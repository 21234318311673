.contact-form-main-div {
    backdrop-filter: blur(10px);
    background-color: rgba(255, 255, 255, 0.1);
  }
  
.bg-opacity-50 {
    background-color: rgba(0, 0, 0, 0.5);
  }
  

  