/* src/fonts/fonts.css */

@font-face {
  font-family: "ClashDisplay-Extralight";
  src: url("./component/assets/fonts/fonts/ClashDisplay-Extralight.ttf")
    format("truetype");
  font-weight: 200;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "ClashDisplay-Light";
  src: url("./component/assets/fonts/fonts/ClashDisplay-Light.ttf")
    format("truetype");
  font-weight: 300;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "ClashDisplay-Regular";
  src: url("./component/assets/fonts/fonts/ClashDisplay-Regular.ttf")
    format("truetype");
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "ClashDisplay-Medium";
  src: url("./component/assets/fonts/fonts/ClashDisplay-Medium.ttf")
    format("truetype");
  font-weight: 600;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "ClashDisplay-Semibold";
  src: url("./component/assets/fonts/fonts/ClashDisplay-Semibold.ttf")
    format("truetype");
  font-weight: 800;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "ClashDisplay-Bold";
  src: url("./component/assets/fonts/fonts/ClashDisplay-Bold.ttf")
    format("truetype");
  font-weight: 900;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "ClashDisplay-Variable";
  src: url("./component/assets/fonts/fonts/ClashDisplay-Variable.ttf")
    format("truetype");
  font-weight: 200 700;
  font-display: swap;
  font-style: normal;
}

/* Tailwind CSS Directives */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* Global Styles */
body {
  margin: 0;
  font-family: "ClashDisplay-Variable", sans-serif;
}
h1 {
  margin: 0;
  font-family: "ClashDisplay-bold", sans-serif;
}
h2 {
  margin: 0;
  font-family: "ClashDisplay-bold", sans-serif;
}
h4 {
  margin: 0;
  font-family: "ClashDisplay-Semibold", sans-serif;
}
h5 {
  margin: 0;
  font-family: "ClashDisplay-Medium", sans-serif;
}
p {
  margin: 0;
  font-family: "ClashDisplay-Regular", sans-serif;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.marquee {
  display: flex;
  width: calc(100% * 15); /* Set to double the width for seamless effect */
  animation: marquee 55s linear infinite;
  white-space: nowrap; /* Prevent wrapping */
}

@keyframes marquee {
  0% {
    transform: translateX(-50%); /* Start from the right */
  }
  100% {
    transform: translateX(0%); /* End at the left */
  }
}

.marquee2 {
  display: flex;
  width: calc(100% * 15); /* Set to double the width for seamless effect */
  animation: marquee2 55s linear infinite;
  white-space: nowrap; /* Prevent wrapping */
}

@keyframes marquee2 {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%); /* Move half the width to create the loop */
  }
}

.swiper {
  width: 340px;
  height: 420px;
}

.swiper-slide {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 18px;
  /* font-size: 22px; */
  font-weight: bold;
  color: #fff;
}

.main-spline-logo #container #hints #logo {
  display: none !important;
}

.main-spline-logo #logo {
  display: none !important;
}

.spline-viewer {
  pointer-events: none;
}

.spline-viewer:hover {
  pointer-events: auto;
}

.second-sec-card-main-div {
  transition: transform 0.9s ease, background-color 0.5s ease, color 0.5s ease !important;
}

.second-sec-card-icn {
  transition: transform 0.9s ease, background-color 0.5s ease, color 0.5s ease !important;
}

.second-sec-card-main-div:hover {
  transform: scale(1.04) !important;
}
.second-sec-card-main-div:hover h3 {
  color: #c0ff00;
}

.second-sec-card-main-div:hover .second-sec-card-img {
  display: none;
}

.second-sec-card-main-div:hover .second-sec-card-hover-img {
  transition: right 1.9s ease; /* Smooth transition */
  display: block;
}

.second-sec-card-icn-div {
  transition: right 1.9s ease;
}

.second-sec-card-icn-div {
  opacity: 0;
  transition: right 1.9s ease;
}

.second-sec-card-main-div:hover .second-sec-card-icn-div {
  display: block;

  opacity: 1;
}

.second-sec-card-main-div {
  transition: transform 0.3s ease, background-color 0.3s ease, color 0.3s ease;
}

.second-sec-card-main-div:hover {
  transform: scale(1.04);
  background-color: #7366fc;
  color: #c0ff00;
}

/* .btn-hover-eft {
  transition-duration: 1s !important;
  transition-delay: 2s !important;
} */
.btn-hover-eft:hover {
  scale: 1.05;
}

.header-main {
  position: fixed; /* Fixed position */
  top: 0; /* Align to the top */
  left: 0; /* Align to the left */
  width: 100%; /* Full width */
  height: 60px; /* Set a height for the header */
  background-color: #7366fc; /* Background color */
  z-index: 1000; /* High z-index to ensure it's above other content */
}
