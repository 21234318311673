.footer-slider-swiper .swiper {
    width: 100% !important;
    height: fit-content !important;
    
    background-color: transparent !important;
  }
  
  .mySwiperFooter .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: none !important;
  
    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent !important;
  }
  
  .mySwiperFooter .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    background-color: transparent !important;
  }
  