.reviewSwiper .swiper {
  width: 340px;
  height: 420px;
}

.reviewSwiper .swiper-slide {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 18px;
  /* font-size: 22px; */
  font-weight: bold;
  color: #fff;
}

.reviewSwiper .swiper-slide:nth-child(1n) {
  background-color: rgba(192, 255, 0, 0.7);
  backdrop-filter: blur(10px);
}
.reviewSwiper .swiper-slide .swiper-div-img {
  background-color: rgba(171, 228, 1, 0.9);
  border: #fff solid 1px;
}

.reviewSwiper .swiper-slide:nth-child(2n) {
  background-color: rgba(116, 103, 254, 0.7);
  backdrop-filter: blur(10px);
}
.reviewSwiper .swiper-slide .swiper-div-img2 {
  background-color: rgba(116, 103, 254, 0.9);
  border: #fff solid 1px;
}

.reviewSwiper .swiper-slide:nth-child(3n) {
  background-color: rgba(159, 150, 255, 0.7);
  backdrop-filter: blur(10px);
}
.reviewSwiper .swiper-slide .swiper-div-img3 {
  background-color: rgba(159, 150, 255, 0.9);
  border: #fff solid 1px;
}

.reviewSwiper .swiper-slide:nth-child(4n) {
  background-color: rgba(244, 250, 255, 0.7);
  backdrop-filter: blur(10px);
}

.reviewSwiper .swiper-slide:nth-child(5n) {
  background-color: rgba(8, 7, 5, 0.7);
  backdrop-filter: blur(10px);
}

.reviewSwiper .swiper-slide:nth-child(6n) {
  background-color: rgb(180, 10, 47);
}

.reviewSwiper .swiper-slide:nth-child(7n) {
  background-color: rgb(35, 99, 19);
}

.reviewSwiper .swiper-slide:nth-child(8n) {
  background-color: rgb(0, 68, 255);
}

.reviewSwiper .swiper-slide:nth-child(9n) {
  background-color: rgb(218, 12, 218, 0.7);
}

.reviewSwiper .swiper-slide:nth-child(10n) {
  background-color: rgb(54, 94, 77);
}

@media screen and (max-width: 480px) {
  .reviewSwiper {
    width: 280px !important;
  }
}
